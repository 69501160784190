import React, { useState, useEffect } from "react";
import "./certificate.css";
import { useParams, useNavigate } from "react-router-dom";
import { CertificatesData } from "../../data/certficatesData";
import { HiArrowLongRight } from "react-icons/hi2";

const Certificates = () => {
  const { id } = useParams(); // Extract ID from URL
  const [certificateId, setCertificateId] = useState("");
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // If ID is in the URL, automatically search for the certificate
    if (id) {
      setCertificateId(id);
      handleVerify(id);
    }
  }, [id]);

  const handleVerify = (idToVerify) => {
    const foundCertificate = CertificatesData.find(
      (cert) => cert.id === idToVerify
    );
    if (foundCertificate) {
      setResult(foundCertificate);
    } else {
      setResult(`Certificate with ID: ${idToVerify} not found`);
    }
  };

  const handleButtonClick = () => {
    navigate(`/certificates/${certificateId}`);
  };

  return (
    <section className="py-4">
      <div className="certificates-header flex flex-col justify-start gap-2 md:flex-row md:items-end  md:justify-between">
        <h1 className="custom-tittle">
          Verify
          <br /> Certificate
        </h1>
        <div className="cert-verfication-form shadow-md flex ">
          <input
            type="text"
            placeholder="Enter Certificate ID"
            value={certificateId}
            onChange={(e) => setCertificateId(e.target.value)}
          />
          <button onClick={handleButtonClick}>
            <HiArrowLongRight />
          </button>
        </div>
      </div>

      {result && (
        <div className="certificate-details-info flex flex-col md:flex-row  items-start justify-between">
          {typeof result === "string" ? (
            <p style={{ color: "red" }}>{result}</p>
          ) : (
            <div className="certificate-details-info flex flex-col md:flex-row gap-2 items-start justify-between">
              <div className="certificate-details-profile">
                <h3>Course: {result.title}</h3>

                <p>
                  Certificate ID: {result.id}
                </p>
                <p>
                  Student Name: {result.firstName} {result.lastName}
                </p>
                <p>
                  Date of Issue: {result.dateOfissue}
                </p>
              </div>
              <div className="certificate-details-outcome">
                <h3>Learning outcomes</h3>
                <ul>
                  {result.outcomes.map((outcome, index) => (
                    <li key={index}>{outcome}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Certificates;
