export const CertificatesData = [
    {
        id:"X11021",
        firstName:"Gabriel",
        lastName:"Asha",
        title:"Frontend Development",
        outcomes:["Proficient in HTML","Proficient in Javascript"],
        dateOfissue:"20 Aug 2022",

    },{
        id:"X11022",
        firstName:"Samuel",
        lastName:"Ogechukwu",
        title:"Frontend Development",
        outcomes:["Proficient in responsive design","Proficient in HTML","Proficient in Javascript"],
        dateOfissue:"20 Aug 2022",
    },{
        id:"X11023",
        firstName:"Michael",
        lastName:"Onafadeji",
        title:"Frontend Development",
        outcomes:["Proficient in HTML","Proficient in Javascript Proficient in HTML"],
        dateOfissue:"20 Aug 2022",
    },{
        id:"X11024",
        firstName:"Joshua",
        lastName:"Nweke",
        title:"Frontend Development",
        outcomes:["Proficient in HTML","Proficient in Javascript"],
        dateOfissue:"20 Aug 2022",
    }
]