import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom"
import {Maintenance, Home, ErrorPage, Certificates} from '../pages'


const Routers = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to="/maintenance" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/maintenance" element={<Maintenance />} />
                <Route path="/certificates" element={<Certificates />} />
                <Route path="/certificates/:id" element={<Certificates />} />
                <Route path="/error" element={<ErrorPage />} />
            </Routes>
        </div>
    )
}

export default Routers
